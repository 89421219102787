/* eslint-disable import/first */
import React from "react"
import { graphql, Link } from "gatsby"
import { LocaleContext } from "../components/layout"
import SEO from "../components/seo"
import { mapObject, groupBy, omit } from "underscore"

const StoresPage = ({ data: { prismicStorespage, allPrismicHomepage } }) => {
  const lang = React.useContext(LocaleContext)
  var i18n = {}
  if (lang.i18n[lang.locale] != undefined) {
    i18n = lang.i18n[lang.locale]
  }

  var groupedStoresByCity = {}

  if (prismicStorespage) {
    groupedStoresByCity = mapObject(
      groupBy(prismicStorespage.data.store, "city"),
      clist => clist.map(store => omit(store, "city"))
    )
  }

  const pricelists = allPrismicHomepage.edges
    .filter(edge => edge.node.lang === lang.locale)
    .map(r => r.node.data.pricelists)

  return (
    <>
      <SEO title="Stores" keywords={[`navigo`, `garmin`]} />

      <div className="container store-page-container">
        <div className="row">
          <div className="col-xs-12 col-sm-8">
            <div className="other-stores-lbl">{i18n.other_stores_title}</div>
            {groupedStoresByCity &&
              Object.keys(groupedStoresByCity).map(function(key) {
                return (
                  <div className="stores-by-city">
                    <div className="city-name">{key}</div>
                    {groupedStoresByCity[key].map(store => (
                      <div
                        style={{
                          display: "block",
                          marginBottom: "15px",
                        }}
                      >
                        {store.store_name && (
                          <div className="store-name">
                            {store.store_name.text}
                          </div>
                        )}
                        {store.website && (
                          <a className="store-email" href={store.website.url}>
                            {store.website.url}
                          </a>
                        )}
                        {store.address && (
                          <div
                            className="store-address"
                            dangerouslySetInnerHTML={{
                              __html: store.address.html,
                            }}
                          />
                        )}
                        {store.email && (
                          <div className="store-address">
                            {i18n.email}
                            <a
                              className="store-email"
                              href={"mailto:" + store.email}
                            >
                              {store.email}
                            </a>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )
              })}
          </div>
          <div className="col-xs-12 col-sm-4">
            <div className="other-stores-lbl"> {i18n.pricelists_title}</div>
            {pricelists.length > 0 &&
              pricelists.map(
                items =>
                  items.length > 0 &&
                  items.map(item => (
                    <a
                      className="store-email"
                      href={item.pricelist_link.url}
                      target="_blank"
                    >
                      {item.pricelist_title}
                      <br />
                    </a>
                  ))
              )}
          </div>
        </div>
      </div>
    </>
  )
}

export default StoresPage

export const pageQuery = graphql`
  query StoresPageQuery($locale: String!) {
    prismicStorespage(lang: { eq: $locale }) {
      data {
        store {
          store_name {
            text
          }
          country
          city
          website {
            url
          }
          address {
            html
          }
          email
        }
      }
    }
    allPrismicHomepage {
      edges {
        node {
          lang
          data {
            pricelists {
              pricelist_title
              pricelist_link {
                url
              }
            }
          }
        }
      }
    }
  }
`
